<template>
   <main v-if="form">
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" label-position="top">
            <Divider orientation="left">{{ form.label }}</Divider>
            <Row>
                <Col :span="9" :offset="3">
                    <editor name="rule-explain" v-model="form.value"/>
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem>
                        <Button type="success" icon="md-filing"  @click="submit">保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    import Editor from '@/components/Editor'

    export default {
        components: {
            Editor
        },
        data()
        {
            return {
                id: this.$route.params.id,
                form: null
            }
        },
        created()
        {
            this.loadData()
        },
        methods: {
            submit()
            {
                this.$http.post('/ruleExplain/update/id/' + this.id, this.form)
                    .then(res => {
                        this.$router.back()
                    })
            },
            loadData()
            {
                this.$http.get('/ruleExplain/detail/id/' + this.id)
                    .then(res => {
                        this.form = res
                    })
            }
        }
    }
</script>